import AddTurnoverModal from "page-sections/data-table/dataTableV2/AddTurnoverModal";
import { FC, useEffect, useState } from "react"
import { DepartmentEntity, DepositEntity, PaymentMethodEntity, SectionEntity, TurnoverItemEntity } from "api/generated";
import { Button, Grid } from "@mui/material";
import { t } from "i18next";
import { departmentsApi, depositsApi, paymentMethodsApi, paymentSectionsApi, turnoverApi } from "api";
import FlexBox from "components/flexbox/FlexBox";
import { Add, ArrowDownward, ArrowUpward, CheckBox, CheckBoxOutlineBlank, Info } from "@mui/icons-material";
import CalendarInput from "components/input-fields/CalendarInput";
import TurnoverDataTable from "page-sections/data-table/TurnoverDataTable";
import SelectSectionModal from "page-sections/data-table/dataTableV2/SelectSectionModal";
import { useSeason } from "contexts/SeasonContext";
import AddDepositModal from "page-sections/data-table/dataTableV2/AddDepositModal";
import SearchInput from "components/input-fields/SearchInput";
import { endOfDay, startOfDay } from "date-fns";

const TurnoverSummary: FC = () => {
    const [openAddTurnoverModal, setOpenAddTurnoverModal] = useState(false);
    const [openAddDepositModal, setOpenAddDepositModal] = useState(false);
    const [openSelectSectionModal, setOpenSelectSectionModal] = useState(false);

    // To set if add deposit modal is opened from table or from button
    const[addMode, setAddMode] = useState(true);
    // To store the default date for depositModal when it's opened from the table.
    const [depositsDate, setDepositDate] = useState(new Date());

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>([]);

    const { seasonId } = useSeason();

    const [departments, setDepartments] = useState<DepartmentEntity[]>([]);
    const [sections, setSections] = useState<SectionEntity[]>([]);

    const [modalDepartments, setModalDepartments] = useState<DepartmentEntity[]>([]);
    const [selectedModalDepartment, setSelectedModalDepartment] = useState<DepartmentEntity>();

    const [modalSections, setModalSections] = useState<SectionEntity[]>([]);
    const [selectedModalSection, setSelectedModalSection] = useState<SectionEntity>();

    const [turnoverItems, setTurnoverItems] = useState<TurnoverItemEntity[]>();

    const [deposits, setDeposits] = useState<DepositEntity[]>();

    const [endDate, setEndDate] = useState(new Date());
    const [isEndDateValid, setIsEndDateValid] = useState(true);
    const [startDate, setStartDate] = useState(() => new Date(new Date().setDate(new Date().getDate() - 6)));
    const [isStartDateValid, setIsStartDateValid] = useState(true);
    const [isEndBeforeStart, setIsEndBeforeStart] = useState(false);

    const [searchValue, setSearchValue] = useState("");

    const [ascSort, setAscSort] = useState(true);
    const [showAllMethods, setShowAllMethods] = useState(true);

    useEffect(() => {
        fetchData();
    }, [])

    const reverseData = () => {
        setSections([...sections].reverse())
        setDepartments([...departments].reverse())
        setPaymentMethods([...paymentMethods].reverse())
    }

    useEffect(() => {
        reverseData();
    }, [ascSort])

    const fetchData = () => {
        paymentMethodsApi.findAll().then(({ data }) => {
            if (ascSort) {
                data.sort((a, b) => {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                })
                setPaymentMethods(data);

            } else {
                data.sort((a, b) => {
                    return -a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                })
                setPaymentMethods(data);
                console.log(data);

            }
        })
        departmentsApi.findByRole(seasonId).then(({ data }) => {
            if (ascSort) {
                data.sort((a, b) => {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())

                })
                setDepartments(data);
            } else {
                data.sort((a, b) => {
                    return -a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                })
                setDepartments(data);
            }
            setModalDepartments(data);
        })
        paymentSectionsApi.findAll(seasonId).then(({ data }) => {
            if (ascSort) {
                data.sort((a, b) => {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())

                })
                setSections(data);
            } else {
                data.sort((a, b) => {
                    return -a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                })
                setSections(data);
            }
            setModalSections(data);
        })
        turnoverApi.findAll(seasonId).then(({ data }) => {
            setTurnoverItems(data);
        })
        depositsApi.findAll(seasonId).then(({ data }) => {
            setDeposits(data);
        })
    }

    const updateDepartment = (department: DepartmentEntity) => {
        setSelectedModalDepartment(department);
        paymentSectionsApi.findAllByDepartment(department.id, seasonId).then(({ data }) => {
            setModalSections(data);
            if (data.length === 1) {
                setSelectedModalSection(data[0]);
            } else {
                setSelectedModalSection(undefined);
            }
        })
    }


    return (
        <>
            <FlexBox marginTop={1} justifyContent={'end'}>
                {modalDepartments.length > 0 && modalSections.length > 0 &&
                    <Button sx={{ marginRight: 1 }} variant="contained" startIcon={<Add />}
                        onClick={() => {
                            setAddMode(true);
                            setOpenAddDepositModal(true);
                        }}>{t('deposit.add')}
                    </Button>
                }
                {modalDepartments.length > 0 && modalSections.length > 0 &&
                    <Button variant="contained" startIcon={<Add />}
                        onClick={() => {
                            if (modalSections.length === 1) {
                                setSelectedModalSection(modalSections[0]);
                            } else if (modalDepartments.length === 1) {
                                setSelectedModalDepartment(modalDepartments[0]);
                                if (modalSections.length === 1) {
                                    setSelectedModalSection(modalSections[0]);
                                }
                            }
                            if (selectedModalSection) {
                                setOpenAddTurnoverModal(true);
                            } else {
                                setOpenSelectSectionModal(true);
                            }
                        }}>{t('amount.add')}</Button>

                }
            </FlexBox>

            <Grid container>
                <Grid item xs={12} md={2} marginTop={2} marginBottom={2} marginRight={1}>
                    <SearchInput
                        bordered={false}
                        placeholder={t('common.tables.button.searchItems', {
                            items: t('menu.apps.sections'),
                        })}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={5} md={2} marginTop={1} marginBottom={2} marginRight={1}>
                    <CalendarInput
                        format='dd.MM.yyyy'
                        value={startDate}
                        onChange={(newValue) => {
                            if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                                setStartDate(newValue);
                                setIsStartDateValid(true);
                                if (+startOfDay(newValue) > +endOfDay(endDate)) {
                                    setIsEndBeforeStart(true);
                                } else {
                                    setIsEndBeforeStart(false);
                                }
                            }
                            else
                                setIsStartDateValid(false);
                        }}
                        slotProps={{
                            textField: {
                                helperText: (!isStartDateValid && t('date.error')),
                                error: !isStartDateValid || isEndBeforeStart
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={5} md={2} marginTop={1} marginBottom={2} marginRight={1}>
                    <CalendarInput
                        format='dd.MM.yyyy'
                        value={endDate}
                        onChange={(newValue) => {

                            if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                                setEndDate(newValue);
                                setIsEndDateValid(true);
                                if (+endOfDay(newValue) < +startOfDay(startDate)) {
                                    setIsEndBeforeStart(true);
                                } else {
                                    setIsEndBeforeStart(false);
                                }
                            }
                            else
                                setIsEndDateValid(false);
                        }}
                        slotProps={{
                            textField: {
                                helperText: !isEndDateValid && t('date.error') || (isEndBeforeStart && t('date.endBeforeStart')),
                                error: !isEndDateValid || isEndBeforeStart
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={8} justifyContent={"start"}>
                    <Button style={{ marginBottom: 20, marginRight: 50 }} startIcon={ascSort ? <ArrowDownward /> : <ArrowUpward />} onClick={() => { setAscSort(!ascSort) }}>
                        {ascSort ? "ABC" : "CBA"}
                    </Button>
                    <Button
                        style={{ marginBottom: 20, marginRight: 20 }}
                        startIcon={showAllMethods ? <CheckBox /> : <CheckBoxOutlineBlank />}
                        onClick={() => { setShowAllMethods(!showAllMethods) }}
                    >
                        {t('turnover.show')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} justifyContent={"end"}>
                    <Button
                        fullWidth
                        style={{ marginBottom: 20 }}
                        startIcon={<Info />}
                        sx={{
                            textAlign: 'start'
                        }}
                    >
                        {t('turnover.infoEdit')}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TurnoverDataTable
                        startDate={startDate}
                        endDate={endDate}
                        turnoverItems={turnoverItems}
                        departments={departments}
                        sections={sections}
                        paymentMethods={paymentMethods}
                        deposits={deposits}
                        fetchData={fetchData}
                        searchValue={searchValue}
                        showEmpty={showAllMethods}
                        openDepositModal={(date: Date) => {
                            setDepositDate(date);
                            setAddMode(false);
                            setOpenAddDepositModal(true);
                        }}
                    />
                </Grid>
            </Grid>

            <SelectSectionModal open={openSelectSectionModal}
                onClose={() => { setOpenSelectSectionModal(false); setSelectedModalDepartment(undefined); setSelectedModalSection(undefined); fetchData() }}
                onConfirm={() => {
                    setOpenAddTurnoverModal(true);
                    setOpenSelectSectionModal(false)
                }}
                setSelectedSection={(section: SectionEntity) => { setSelectedModalSection(section) }}
                sections={modalSections}
                selectedSection={selectedModalSection}
                setSelectedDepartment={(department: DepartmentEntity) => { updateDepartment(department) }}
                departments={modalDepartments}
                selectedDepartment={selectedModalDepartment}
            />

            <AddTurnoverModal
                section={selectedModalSection}
                paymentMethods={paymentMethods}
                open={openAddTurnoverModal}
                onClose={() => { setOpenAddTurnoverModal(false); setSelectedModalDepartment(undefined); setSelectedModalSection(undefined); fetchData() }}
                onBack={() => {
                    setOpenAddTurnoverModal(false);
                    if (modalSections.length === 1 && modalDepartments.length === 1) {
                        setSelectedModalDepartment(undefined);
                        setSelectedModalSection(undefined);
                        fetchData();
                    } else {
                        setOpenSelectSectionModal(true);
                    }
                }}
            />
            <AddDepositModal
                open={openAddDepositModal}
                onClose={() => { setOpenAddDepositModal(false); fetchData() }}
                sections={modalSections}
                departments={modalDepartments}
                addMode={addMode}
                defaultDate={depositsDate}
            />
        </>
    )
}

export default TurnoverSummary;