import { Autocomplete, Box, Grid, Switch } from '@mui/material';
import { H6, Tiny } from 'components/Typography';
import AppTextField from 'components/input-fields/AppTextField';
import { ChangeEvent, type FC, useState, useEffect, useRef, Fragment } from 'react';
import {
  CreateEmployeeDto,
  CreateEmployeeDtoGenderEnum,
  CreateEmployeeDtoRoleEnum,
  WorkPositionEntity,
} from '../../api/generated';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import TabBase, { TabResourceProps } from './TabBase';
import provinces from "../../assets/geographics/provinces.json"
import CodiceFiscale from 'codice-fiscale-js';
import AppSelectField from 'components/input-fields/MultipleChoiceField';
import { KeyboardArrowDown } from '@mui/icons-material';
import CalendarInput from 'components/input-fields/CalendarInput';
import CountrySelect from 'components/input-fields/CountrySelector';
import countries from "../../assets/geographics/countries.json"
import countriesITA from "../../assets/geographics/countries_IT.json"
import toast, { Toaster } from 'react-hot-toast';
import { departmentsEmployeesApi, workPositionsApi } from 'api';
import { useSeason } from 'contexts/SeasonContext';

export type BasicInformationFormData = CreateEmployeeDto;
const BasicInformation: FC<TabResourceProps<BasicInformationFormData>> = ({
  data,
  id,
  onSubmit,
  isProfileCompletition = false,
  setNewDataCallback,
  userMode
}) => {
  type GenderCodFisc = "F" | "M";
  interface Country {
    code: string;
    label: string;
    phone: string;
  }
  const findCountryByCode = (code: string) => {
    return countries.find((country) => country.code === code);
  };

  const initialCountry = data?.countryOfBirth ? findCountryByCode(data.countryOfBirth) : undefined;
  const { t } = useTranslation();
  const [isViewing, setIsViewing] = useState(!!data && !isProfileCompletition);
  const [country, setCountry] = useState<Country>(initialCountry as Country);
  const [options, setOptions] = useState<string[]>([]);
  const [townshipOptions, setTownshipOptions] = useState<string[]>([]);
  const [isTownshipDisabled, setIsTownshipDisabled] = useState<boolean>(data?.townshipOfBirth === undefined);
  const [isProvinceDisabled, setIsProvinceDisabled] = useState<boolean>(data?.provinceOfBirth === undefined);
  const [taxcodeError, setTaxcodeError] = useState(false);
  const [explicitSelection, setExplicitSelection] = useState({ province: false, township: false });
  const [workPositions, setWorkPositions] = useState<WorkPositionEntity[]>([]);
  const [oldSelectedWorkPositions, setOldSelectedWorkPositions] = useState<WorkPositionEntity[]>([]);
  const [selectedWorkPositions, setSelectedWorkPositions] = useState<WorkPositionEntity[]>([]);

  const { seasonId } = useSeason();

  const fetchWorkPositions = () => {
    workPositionsApi.findAll().then(({ data }) => {
      setWorkPositions(data);
    })
    if (id)
      workPositionsApi.findByEmployee(id).then(({ data }) => {
        setOldSelectedWorkPositions(data);
        setSelectedWorkPositions(data);
      })
  }

  const GenderEnum = [
    { name: t('employees.male'), value: CreateEmployeeDtoGenderEnum.Male },
    { name: t('employees.female'), value: CreateEmployeeDtoGenderEnum.Female },
  ];

  useEffect(() => {
    const sortedProvinces = [...provinces].sort((a, b) => a.localeCompare(b));
    setOptions(sortedProvinces);
  }, []);

  useEffect(() => {
    fetchWorkPositions();
  }, [id]);

  const phoneRegExp =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
  const initialValues: BasicInformationFormData = {
    dateOfBirth: data?.dateOfBirth ?? null,
    email: data?.email ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    phone: data?.phone ?? '',
    role: data?.role ?? CreateEmployeeDtoRoleEnum.User,
    gender: data?.gender ?? CreateEmployeeDtoGenderEnum.Undefined,
    provinceOfBirth: data?.provinceOfBirth ?? '',
    townshipOfBirth: data?.townshipOfBirth ?? '',
    countryOfBirth: data?.countryOfBirth ?? '',
    taxcode: data?.taxcode ?? '',
    filePath: data?.filePath ?? null,
    // statusId: data?.statusId ?? null,
    lastPayment: data?.lastPayment ?? null,
    seasonId: data?.seasonId ?? null,
  };
  // Just a check to avoid having an inconsistent province - township
  useEffect(() => {
    if (data?.countryOfBirth !== "IT") {
      setIsProvinceDisabled(true);
      setIsTownshipDisabled(true);
      formik.setFieldValue("provinceOfBirth", "");
      formik.setFieldValue("townshipOfBirth", "");
    }
  }, [data?.countryOfBirth]);
  const validationSchema = Yup.object({
    firstName: Yup.string().required(
      t('common.forms.field.required', {
        field: t('employees.generalInformation.firstName'),
      }),
    ),
    lastName: Yup.string().required(
      t('common.forms.field.required', {
        field: t('employees.generalInformation.lastName'),
      }),
    ),
    email: Yup.string()
      .email(
        t('common.forms.field.invalid', {
          field: t('employees.generalInformation.email'),
        }),
      )
    /*  .required(
        t('common.forms.field.required', {
          field: t('employees.generalInformation.email'),
        }),
      ) */
    ,
    phone: Yup.string()
      .matches(
        phoneRegExp,
        t('common.forms.field.invalid', {
          field: t('employees.generalInformation.phone'),
        }),
      ),
    dateOfBirth: Yup.string().notRequired(),
    role: Yup.mixed<CreateEmployeeDtoRoleEnum>()
      .oneOf(Object.values(CreateEmployeeDtoRoleEnum)),

    provinceOfBirth: Yup.string().when((values) => {
      if (country?.code === 'IT') {
        return Yup.string();
      }
      return Yup.string().notRequired();
    }),
    townshipOfBirth: Yup.string().when((values) => {
      if (country?.code === 'IT') {
        return Yup.string();
      }
      return Yup.string().notRequired();
    }),

    countryOfBirth: Yup.string(),
    gender: Yup.string()
  });
  const formikSubmit = async (values: CreateEmployeeDto) => {
    const employee = await onSubmit(values);
    const employeeId = id ?? employee.id;
    if (employeeId) {
      for (const workPosition of workPositions) {
        if (!oldSelectedWorkPositions.find((wp) => { return wp.id === workPosition.id }) &&
          selectedWorkPositions.find((wp) => { return wp.id === workPosition.id })
        ) {
          departmentsEmployeesApi.create(
            String(workPosition.departmentId),
            String(employeeId),
            {
              workPositionId: workPosition.id,
              bossEmployeeId: null
            },
            seasonId
          )
        } else if (oldSelectedWorkPositions.find((wp) => { return wp.id === workPosition.id }) &&
          !selectedWorkPositions.find((wp) => { return wp.id === workPosition.id })) {
          departmentsEmployeesApi.remove(
            String(workPosition.departmentId),
            String(employeeId),
            seasonId
          )
        }
      }
    }

  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: formikSubmit
  });
  const { values, handleBlur, handleChange, touched, errors, handleSubmit } =
    formik;
  useEffect(() => {
    if (formik.values.provinceOfBirth) {
      setIsTownshipDisabled(false);
      const sanitizedFileName = formik.values.provinceOfBirth.replace(/[^a-zA-Z0-9 -]/g, '');

      import(`../../assets/geographics/townships/${sanitizedFileName}.json`)
        .then((data) => {

          const sortedTownships = [...data.default].sort((a, b) => a.localeCompare(b));
          setTownshipOptions(sortedTownships);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setIsTownshipDisabled(true);
      setTownshipOptions([]);
    }
  }, [formik.values.provinceOfBirth]);

  const handleRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(
      event.target.name,
      event.target.checked
        ? CreateEmployeeDtoRoleEnum.Manager
        : CreateEmployeeDtoRoleEnum.User,
    );
  };

  const isManager = () => {
    return (
      values.role === CreateEmployeeDtoRoleEnum.Manager ||
      values.role === CreateEmployeeDtoRoleEnum.Owner
    );
  };
  const [dateOfBirthValue, setdateOfBirthValue] = useState(formik.values.dateOfBirth !== null ? new Date(formik.values.dateOfBirth) : null);
  const lastCountryOfBirth = useRef(country);
  const isTaxCodeManuallyEdited = useRef(false);
  const hasDisplayedErrorForCurrentCountry = useRef(false);

  /**
   * This section is all about the calculation of the italian taxcode of physical people
   */

  /**
   * As for now, once the taxcode field is touched by the user the automatic calculation
   * will be stopped and priority will be given to the user input
   */
  const handleManualChange = (e: any) => {
    isTaxCodeManuallyEdited.current = true;
    formik.handleChange(e);
  };
  /**
   * The taxcode calculation either required the italian name of the township of birth
   * or for foreigners not born in Italy the name of their country of birth in italian.
   * This function decieds which value is necessary and returns it
   * @returns Either the townshipOfBirth or the countryOfBirth if not italian
   */
  const getBirthplaceInfo = () => {
    const { countryOfBirth, townshipOfBirth } = formik.values;
    const italianCountryName = countriesITA.find(country => country.code === countryOfBirth);
    return countryOfBirth === "IT" ? townshipOfBirth.split("/")[0] : italianCountryName?.label ?? "";
  };
  /* This fucntion only calls the used npm library with the correct variables
   * @returns The taxcode
   * @throws Excpetion e if township does not exist
   */
  const calculateTaxCode = () => {
    const { firstName, lastName, gender, dateOfBirth } = formik.values;
    const birthdateComponents = dateOfBirth?.split("-").map(str => parseInt(str)) || [];
    const [year, month, day] = birthdateComponents;
    return new CodiceFiscale({
      name: firstName,
      surname: lastName,
      gender: gender[0] as GenderCodFisc,
      day,
      month,
      year,
      birthplace: getBirthplaceInfo(),
      birthplaceProvincia: formik.values.countryOfBirth === "IT" ? "" : "EE"
    });
  };
  /**
   * This function checks, if all the fields required for the calculation of the taxcode 
   * have already been filled by the user
   * @returns True if taxcode can be calculated, else false
   */
  const areRequiredFieldsFilled = (data?: CreateEmployeeDto) => {
    if (!data)
      return;
    const { firstName, lastName, dateOfBirth, townshipOfBirth, countryOfBirth } = data;
    const gender = (data?.gender ?? formik.values.gender) !== CreateEmployeeDtoGenderEnum.Undefined;
    return firstName && lastName && gender && dateOfBirth && (townshipOfBirth || countryOfBirth !== "IT");
  };

  /**
   * This fucntion will dispaly the toast telling the user to set the taxcode manually
   */
  useEffect(() => {
    if (taxcodeError && !hasDisplayedErrorForCurrentCountry.current && !isViewing) {
      toast.error(t('employees.generalInformation.taxCodeError'), { duration: 5000 });
      hasDisplayedErrorForCurrentCountry.current = true;
    }
  }, [taxcodeError]);

  /**
   * This function is the main one for the taxcode calculation. It checks if the field
   * was edited manually, if all the fields required to calculate the taxcode have been 
   * filled and if the country has changed since the last calculation (if there was an error)
   * with the country the user should see the error message just one time, even if the calculation
   * fails again. The user has already been informed that this country does not work and that he
   * need to input the code manually
   */
  useEffect(() => {

    if (isTaxCodeManuallyEdited.current)
      return

    // If a taxcode is already saved on DB do not regenerate it
    if (data?.taxcode || areRequiredFieldsFilled(data)) {
      return;
    }
    if (!areRequiredFieldsFilled(values)) {
      return;
    }
    // Check if country has changed
    const isNewCountry = country && lastCountryOfBirth.current?.code !== country.code;
    if (isNewCountry) {
      hasDisplayedErrorForCurrentCountry.current = false;
      lastCountryOfBirth.current = country;
    }
    try {
      const newTaxCode = calculateTaxCode();
      formik.setFieldValue("taxcode", newTaxCode.toString(), true);
      setTaxcodeError(false);
    } catch (error) {
      if (isNewCountry || !hasDisplayedErrorForCurrentCountry.current) {
        setTaxcodeError(true);
      }
      formik.setFieldValue('taxcode', '', true);
    }
  }, [formik.values, country]);

  useEffect(() => {
    if (setNewDataCallback) {
      setNewDataCallback(formik.values);
    }
  }, [formik.values]);

  return (
    <TabBase
      title={t('employees.generalInformation.title')}
      setIsViewing={setIsViewing}
      isViewing={isViewing}
      handleSubmit={handleSubmit}
      isProfileCompletition={isProfileCompletition}
    >
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <AppTextField
            fullWidth
            disabled={isViewing}
            name='firstName'
            label={t('employees.generalInformation.firstName')}
            variant='outlined'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            helperText={touched.firstName && (errors.firstName as string)}
            error={Boolean(touched.firstName && (errors.firstName as string))}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <AppTextField
            fullWidth
            disabled={isViewing}
            name='lastName'
            label={t('employees.generalInformation.lastName')}
            variant='outlined'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            helperText={touched.lastName && (errors.lastName as string)}
            error={Boolean(touched.lastName && (errors.lastName as string))}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <AppTextField
            fullWidth
            disabled={isViewing}
            name='email'
            label={t('employees.generalInformation.email')}
            variant='outlined'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            helperText={touched.email && (errors.email as string)}
            error={Boolean(touched.email && (errors.email as string))}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <AppTextField
            fullWidth
            disabled={isViewing}
            name='phone'
            label={t('employees.generalInformation.phone')}
            variant='outlined'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            helperText={touched.phone && (errors.phone as string)}
            error={Boolean(touched.phone && (errors.phone as string))}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CountrySelect
            value={country}
            showCode={false}
            showPhone={false}
            disabled={isViewing}
            onChange={async (value, reason) => {
              // There is a known bug about this --> MT-72
              setCountry(value);
              // In order to allow for l10n we only save the country code in the form
              await formik.setFieldValue('countryOfBirth', value?.code, true);
              if (value?.code && value.code === "IT") {
                setIsProvinceDisabled(false)
              } else {
                setIsProvinceDisabled(true);
                setIsTownshipDisabled(true);
                await formik.setFieldValue("provinceOfBirth", "");
                await formik.setFieldValue("townshipOfBirth", "");
              }
            }}
            label={t('employees.generalInformation.country')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CalendarInput
            disabled={isViewing}
            format='dd.MM.yyyy'
            label={t('employees.generalInformation.dateOfBirth.label')}
            disableFuture={true}
            localeText={{
              fieldDayPlaceholder: () => t("DD"),
              fieldMonthPlaceholder: () => t("MM"),
              fieldYearPlaceholder: () => t("YYYY"),
            }}
            value={dateOfBirthValue}
            onChange={(newValue) => {
              /* Check if newValue is a valid date before updating formik's state.
               This ensures that we only set 'dateOfBirth' when the user has entered or selected a complete and valid date.
               Without this check, incomplete or invalid dates might get saved into the form state, causing potential issues downstream.
               This occurs especially if the user enters the date via the keyboard and not opening the datepicker
               */
              if (newValue && !isNaN(new Date(newValue).getTime())) {
                const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                formik.setFieldValue('dateOfBirth', utcDate?.toISOString());
                setdateOfBirthValue(newValue);
              }
            }}
            slotProps={{
              textField: {
                helperText: t("DD") + "." + t("MM") + "." + t("YYYY")
              },
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Autocomplete
            value={formik.values.provinceOfBirth}
            disabled={isViewing || isProvinceDisabled}
            // Do not allow values not contained in the autocomplete suggestions
            freeSolo={false}
            options={options}
            getOptionLabel={(option) => option}
            /*  When changing focus from this field check if a province was already uniquely 
                identified, if yes complete it automatically and set it for the townships,
                otherwise reset the field and block the township field until a valid province
                is specified
            */
            onBlur={async (e) => {
              if (explicitSelection.province) {
                return;
              }
              const eventTarget = e.target as HTMLInputElement;
              const value = eventTarget.value;
              const remainingOptions = options.filter(option =>
                option.toLowerCase().includes(value.toLowerCase())
              );
              // Check if only one province remains
              if (remainingOptions.length === 1) {
                const singleOption = remainingOptions[0];
                await formik.setFieldValue("provinceOfBirth", singleOption, true)
              }
              // Otherwise clear fields
              else {
                await formik.setFieldValue("provinceOfBirth", "", true)
              }
              handleBlur(e);
            }}
            // This handles setting the province by clickin on an auto-suggestion
            onChange={async (event, value) => {
              if (value) {
                setExplicitSelection({ ...explicitSelection, province: true });
                await formik.setFieldValue('provinceOfBirth', value, true);
              }
            }}
            renderInput={(params) => {
              return (
                <AppTextField
                  {...params}
                  fullWidth
                  disabled={isViewing || isProvinceDisabled}
                  name='provinceOfBirth'
                  label={t('employees.generalInformation.province')}
                  variant='outlined'
                  onFocus={() => {
                    setExplicitSelection({ ...explicitSelection, province: false });
                  }}
                  value={formik.values.provinceOfBirth}
                  onChange={handleChange}
                  helperText={touched.provinceOfBirth && (errors.provinceOfBirth as string)}
                  error={Boolean(touched.provinceOfBirth && (errors.provinceOfBirth as string))}
                />
              )
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Autocomplete
            value={formik.values.townshipOfBirth}
            disabled={isViewing || isTownshipDisabled}
            // Do not allow values not contained in the autocomplete suggestions
            freeSolo={false}
            options={townshipOptions}
            getOptionLabel={(option) => option}
            /* When changing focus from this field check if a township was already uniquely
               identified, if yes complete it automatically, otherwise reset the field.
            */
            onBlur={async (e) => {
              if (explicitSelection.township) {
                return;
              }
              const eventTarget = e.target as HTMLInputElement;
              const value = eventTarget.value;
              const remainingOptions = townshipOptions.filter(option =>
                option.toLowerCase().includes(value.toLowerCase())
              );
              // Check if only one township remains
              if (remainingOptions.length === 1) {
                const singleOption = remainingOptions[0];
                await formik.setFieldValue('townshipOfBirth', singleOption, true);
              }
              // Otherwise clear fields
              else {
                await formik.setFieldValue('townshipOfBirth', "", true);
              }
              handleBlur(e);
            }}
            // This handles setting the township by clicking on an auto-suggestion
            onChange={async (event, value) => {
              if (value) {
                setExplicitSelection({ ...explicitSelection, township: true });
                await formik.setFieldValue('townshipOfBirth', value, true);
              }
            }}
            renderInput={(params) => {
              return (
                <AppTextField
                  {...params}
                  fullWidth
                  disabled={isViewing || isTownshipDisabled}
                  name='townshipOfBirth'
                  label={t('employees.generalInformation.township')}
                  variant='outlined'
                  value={formik.values.townshipOfBirth}
                  onFocus={() => {
                    setExplicitSelection({ ...explicitSelection, township: false });
                  }}
                  helperText={touched.townshipOfBirth && (errors.townshipOfBirth as string)}
                  error={Boolean(touched.townshipOfBirth && (errors.townshipOfBirth as string))}
                />
              )
            }}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <AppSelectField
            disabled={isViewing}
            InputLabelProps={{ shrink: true }}
            select
            fullWidth
            name='gender'
            variant='outlined'
            label={t('employees.generalInformation.gender.label')}
            placeholder=''
            SelectProps={{
              native: true,
              IconComponent: KeyboardArrowDown,
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={formik.values.gender}
            helperText={touched.gender && (errors.gender as string)}
            error={Boolean(touched.gender && (errors.gender as string))}
          >
            <option value={"UNDEFINED"}>
              {t('employees.generalInformation.gender.default')}
            </option>

            {GenderEnum.map((gender) => (
              <option key={gender.value} value={gender.value}>
                {gender.name}
              </option>
            ))}
          </AppSelectField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <AppTextField
            fullWidth
            disabled={isViewing}
            name='taxcode'
            label={t('employees.generalInformation.taxcode')}
            variant='outlined'
            onChange={handleManualChange}
            onBlur={handleBlur}
            value={formik.values.taxcode}
            helperText={touched.taxcode && (errors.taxcode as string)}
            error={Boolean(touched.taxcode && errors.taxcode)}
          />
        </Grid>
        {(values.role !== CreateEmployeeDtoRoleEnum.Owner) && !userMode && !isProfileCompletition &&
          <>
            <Fragment>
              <Grid item sm={6} xs={12}>
                <Box>
                  <H6 fontSize={14} mb={0.5}>
                    {t('employees.generalInformation.manager.create')}
                  </H6>
                  <Tiny fontWeight={500}>
                    {t('employees.generalInformation.manager.description')}
                  </Tiny>
                </Box>
                <Switch
                  name='role'
                  disabled={isViewing}
                  onChange={handleRoleChange}
                  onBlur={handleBlur}
                  checked={isManager()}
                />
              </Grid>

              <Toaster />
            </Fragment>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                sx={{ display: 'flex' }}

                disabled={isViewing}
                options={
                  workPositions.filter((wp) => {
                    return !selectedWorkPositions.find((selected) => { return selected.departmentId === wp.departmentId })
                  }).map((wp) => ({ id: wp.id, label: wp.name + " (" + wp.department?.name + ")" }))
                }
                multiple
                value={selectedWorkPositions.map(x => ({ id: x.id, label: x.name + " (" + x.department?.name + ")" }))}
                getOptionLabel={(value) => {
                  return value.label
                }}
                renderInput={(params) => <AppTextField rows={2} multiline {...params} />}
                onChange={(e, values) => {
                  setSelectedWorkPositions(workPositions.filter((wp) => { return !!values.find((value) => { return value.id === wp.id }) }))
                }}
              />
            </Grid>
          </>
        }
      </Grid>
    </TabBase>
  );
};

export default BasicInformation;
