import { alpha, Box, ButtonBase, styled } from '@mui/material';
import { Paragraph, Span } from 'components/Typography';
import { type FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarAccordion from './SidebarAccordion';
import duotone from '../../icons/duotone';
import Education from '../../icons/Education';
import Building from '../../icons/City';
import { useTranslation } from 'react-i18next';
import { ReportIcon } from 'icons/report/reactIcons';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import WorkIcon from '@mui/icons-material/Work';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import { checkTokenRole } from 'utils/checkToken';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

interface Active {
  active: any;
}

interface Compact {
  compact: number;
}

type ActiveCompact = Active & Compact;

const NavItemButton = styled(ButtonBase)<Active>(({ theme, active }) => ({
  height: 44,
  width: '100%',
  borderRadius: 8,
  marginBottom: 4,
  padding: '0 18px',
  justifyContent: 'flex-start',
  transition: 'all 0.15s ease',
  ...(active && {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.06),
  }),
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const ListLabel = styled(Paragraph)<Compact>(({ theme, compact }) => ({
  fontWeight: 700,
  fontSize: '12px',
  marginTop: '20px',
  marginLeft: '15px',
  marginBottom: '10px',
  textTransform: 'uppercase',
  transition: 'all 0.15s ease',
  color: theme.palette.text.secondary,
  ...(compact && { opacity: 0, width: 0 }),
}));

const ExternalLink = styled('a')(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'pre',
  marginBottom: '8px',
  textDecoration: 'none',
  color: theme.palette.text.primary,
}));

const StyledText = styled(Span)<ActiveCompact>(
  ({ theme, compact, active }) => ({
    whiteSpace: 'nowrap',
    paddingLeft: '0.8rem',
    transition: 'all 0.15s ease',
    fontSize: '13px',
    fontWeight: 500,
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    ...(compact && { opacity: 0, width: 0 }),
  }),
);

const BulletIcon = styled('div')<Active>(({ theme, active }) => ({
  width: 4,
  height: 4,
  marginLeft: '10px',
  marginRight: '8px',
  overflow: 'hidden',
  borderRadius: '50%',
  background: active ? theme.palette.primary.main : theme.palette.text.disabled,
  boxShadow: active ? `0px 0px 0px 3px ${theme.palette.primary[200]}` : 'none',
}));

const BadgeValue = styled(Box)<Compact>(({ compact, theme }) => ({
  color: 'white',
  fontSize: '12px',
  fontWeight: 500,
  padding: '1px 6px',
  overflow: 'hidden',
  borderRadius: '300px',
  backgroundColor: theme.palette.primary.main,
  display: compact ? 'none' : 'unset',
}));

// Common icon style
const iconStyle = (active: any) => ({
  fontSize: 18,
  marginRight: '4px',
  color: active ? 'primary.main' : 'text.secondary',
});

// Sidebar container style to ensure proper scrolling behavior
const SidebarContainer = styled(Box)({
  height: '100%',
  overflowY: 'auto',
  marginBottom: '150px', // To avoid content being cut off by the scrollbar
  boxSizing: 'border-box',
});

// ---------------------------------------------------------------
interface MultiLevelMenuProps {
  sidebarCompact: boolean;
}

// ---------------------------------------------------------------

const MultiLevelMenu: FC<MultiLevelMenuProps> = ({ sidebarCompact }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let role = '';
  try {
    role = checkTokenRole();
  }
  catch (e) {
    console.error(e);
  }

  const navigations = (() => {
    switch (role) {
      case 'DEPCHIEF':
        return [
          {
            name: t('menu.apps.workShifts'),
            icon: duotone.Calender,
            path: '/dashboard/employees/calendar/false',
          },
          {
            icon: duotone.DiagramProject,
            name: t('departments.employees.edit'),
            path: '/dashboard/employees-hierarchy',
          },
          {
            name: t("menu.management.shift"),
            icon: WorkHistoryIcon,
            children: [
              {
                name: t("menu.management.submenus.shift.template"),
                icon: CalendarViewDayIcon,
                path: '/dashboard/shift-template',
              },
              {
                name: t('menu.management.submenus.shift.calendar'),
                icon: duotone.Calender,
                path: '/dashboard/shift-template/calendar',
              },
              {
                name: t('menu.management.submenus.shift.assignment'),
                icon: ViewTimelineIcon,
                path: '/dashboard/shift-assignments'
              }
            ]
          },
          {
            name: t('menu.apps.summary'),
            icon: duotone.RequestQuote,
            path: 'turnover/summary'
          },
          {
            name: t('menu.apps.summary'),
            icon: duotone.RequestQuote,
            path: 'turnover/summary'
          }
        ];
      case 'USER':
        return [
          {
            name: t('user.personalInfo'),
            icon: duotone.UserProfile,
            path: '/employee/info'
          },
          {
            name: t('menu.apps.workShifts'),
            icon: duotone.Calender,
            path: '/employee/calendar'
          },
          {
            name: t('menu.apps.chat'),
            icon: duotone.Chat,
            path: '/employee/chat'
          },
          {
            name: t("menu.management.shift"),
            icon: WorkHistoryIcon,
            path: '/employee/calendar'
          }
        ];
      default:
        return [
          { type: 'label', label: t('menu.recruiting.label') },
          {
            name: t('menu.recruiting.candidates'),
            path: '/dashboard/employees/candidates',
            icon: duotone.PersonCircleCheck,
          },

          {
            name: t('jobAdvertisements.collectionName'),
            icon: WorkIcon,
            path: '/dashboard/job-advertisements',
          },

          { type: 'label', label: t('menu.apps.label') },
          {
            name: t('menu.dashboard.recruitment'),
            path: '/dashboard',
            icon: duotone.Dashboard,
          },
          {
            icon: duotone.UserList,
            name: t('menu.management.employees'),
            path: '/dashboard/employees/',
            children: [

              {
                icon: duotone.UserProfile,
                name: t('menu.management.submenus.employees.AllEmployees'),
                path: '/dashboard/employees/',
              },
              {
                icon: duotone.UserProfile,
                name: t('menu.management.submenus.employees.AnnualEmployees'),
                path: '/dashboard/employees/annualemployees',
              },
              {
                icon: duotone.UserProfile,
                name: t('menu.management.submenus.employees.SeasonalEmployees'),
                path: '/dashboard/employees/seasonalemployees',
              }
            ],
          },
          {
            icon: PendingActionsIcon,
            name: t('dashboard.expiration'),
            path: '/dashboard/expiration'
          },
          {
            name: t('menu.dashboard.details'),
            path: '/dashboard/employees/new',
            icon: duotone.PersonChalkboard,
          },
          {
            name: t('menu.apps.workShifts'),
            icon: duotone.Calender,
            path: '/dashboard/employees/calendar/false',
          },
          {
            name: t('report.reportname'),
            icon: ReportIcon,
            path: '/dashboard/employees/report',

          },
          {
            name: t('menu.apps.chat'),
            icon: duotone.Chat,
            path: '/dashboard/employees/communications',

          },
          {
            icon: duotone.DiagramProject,
            name: t('departments.employees.edit'),
            path: '/dashboard/employees-hierarchy',
          },
          { type: 'label', label: t('menu.apps.amounts') },

          {
            name: t('menu.apps.paymentMethods'),
            icon: duotone.MoneyIcon,
            path: 'turnover/paymentMethods',
          },
          {
            name: t('menu.apps.paymentSections'),
            icon: duotone.Store,
            path: 'turnover/sections',
          },
          {
            name: t('menu.apps.summary'),
            icon: duotone.RequestQuote,
            path: 'turnover/summary'
          },

          { type: 'label', label: t('menu.management.label') },
          {
            name: t('menu.management.companyStructure.label'),
            icon: duotone.Settings,
            children: [
              {
                icon: WorkspacesIcon,
                name: t('department.segments'),
                path: '/dashboard/segments',
              },
              {
                icon: duotone.DiagramProject,
                name: t('menu.management.companyStructure.departments'),
                path: '/dashboard/departments',
              },
              /*  {
                  icon: duotone.BadgeDollar,
                  name: t('menu.management.companyStructure.contractTypes'),
                  path: '/dashboard/contracts',
                }, */
              {
                icon: Education,
                name: t('menu.management.companyStructure.experienceLevels'),
                path: '/dashboard/experience-levels',
              },
              {
                icon: Building,
                name: t('menu.management.companyStructure.buildings'),
                path: '/dashboard/buildings',
              },
            ],
          },
          {
            name: t('menu.management.seasons'),
            // icon: duotone,
            icon: duotone.ElementHub,
            path: '/dashboard/seasons',
          },
          {
            name: t("menu.management.shift"),
            icon: WorkHistoryIcon,
            children: [
              {
                name: t("menu.management.submenus.shift.template"),
                icon: CalendarViewDayIcon,
                path: '/dashboard/shift-template',
              },
              {
                name: t('menu.management.submenus.shift.calendar'),
                icon: duotone.Calender,
                path: '/dashboard/shift-template/calendar',
              },
              {
                name: t('menu.management.submenus.shift.assignment'),
                icon: ViewTimelineIcon,
                path: '/dashboard/shift-assignments'
              }
            ]
          },
        ];
    }
  })();


  // handle active current page
  const activeRoute = (path: string) => (pathname === path ? 1 : 0);
  // handle navigate to another route or page
  const handleNavigation = (path: string) => {
    navigate(path);
  };

  // ACTIVATE SIDEBAR COMPACT
  const COMPACT = sidebarCompact ? 1 : 0;

  //   RECURSIVE FUNCTION TO RENDER MULTI LEVEL MENU
  const renderLevels = (data: any) => {
    return data.map((item: any, index: any) => {
      if (item.type === 'label')
        return (
          <ListLabel key={index} compact={COMPACT}>
            {item.label}
          </ListLabel>
        );

      if (item.children) {
        return (
          <SidebarAccordion key={index} item={item} sidebarCompact={COMPACT}>
            {renderLevels(item.children)}
          </SidebarAccordion>
        );
      } else if (item.type === 'extLink') {
        return (
          <ExternalLink
            key={index}
            href={item.path}
            rel="noopener noreferrer"
            target="_blank"
          >
            <NavItemButton key={item.name} name="child" active={0}>
              {(() => {
                if (item.icon) {
                  return <item.icon sx={iconStyle(0)} />;
                } else {
                  return (
                    <span className="item-icon icon-text">{item.iconText}</span>
                  );
                }
              })()}

              <StyledText compact={COMPACT} active={activeRoute(item.path)}>
                {item.name}
              </StyledText>

              <Box mx="auto" />

              {item.badge && (
                <BadgeValue compact={COMPACT}>{item.badge.value}</BadgeValue>
              )}
            </NavItemButton>
          </ExternalLink>
        );
      } else {
        return (
          <Box key={index}>
            <NavItemButton
              key={item.name}
              className="navItem"
              active={activeRoute(item.path)}
              onClick={() => {
                handleNavigation(item.path);
              }}
            >
              {item?.icon ? (
                <item.icon sx={iconStyle(activeRoute(item.path))} />
              ) : (
                <BulletIcon active={activeRoute(item.path)} />
              )}

              <StyledText compact={COMPACT} active={activeRoute(item.path)}>
                {item.name}
              </StyledText>

              <Box mx="auto" />

              {item.badge && (
                <BadgeValue compact={COMPACT}>{item.badge.value}</BadgeValue>
              )}
            </NavItemButton>
          </Box>
        );
      }
    });
  };

  return <SidebarContainer>{renderLevels(navigations)}</SidebarContainer>;
};

export default MultiLevelMenu;
