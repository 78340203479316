import { Small, Tiny } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "./DataTable";
import { PaymentMethodEntity } from "api/generated";
import { Button } from "@mui/material";
import { Check, Close } from "@mui/icons-material";

interface DataTableProps {
    data?: PaymentMethodEntity[];
    handleRowSelect: (rows: []) => void;
    editCallback: (data: PaymentMethodEntity) => void;
}


const PaymentMethodsDataTable: FC<DataTableProps> = (props) => {

    const { t } = useTranslation()
    const columns: any = useMemo(() => getColumnShape(props.data), [t]);

    function handleModify(data: PaymentMethodEntity) {
        props.editCallback(data);
    };

    function getColumnShape(data: any) {
        return [
            {
                minWidth: 50,
                Header: t('departments.name'),
                accessor: 'name',
                Cell: ({ row }: any) => {
                    const { name, id } = row.original;
                    return (
                        <FlexBox alignItems='center'>
                            <FlexBox flexDirection='column' ml={1.2}>
                                <Small mb={0.5}>{name}</Small>
                                <Tiny sx={{ fontSize: 10, color: 'text.secondary' }}>
                                    ID: {id}
                                </Tiny>
                            </FlexBox>
                        </FlexBox>
                    );
                }
            },
            {
                minWidth: 50,
                Header: t('paymentMethod.description'),
                accessor: 'description',
                Cell: ({ row }: any) => {
                    const { description } = row.original;
                    return (
                        <FlexBox alignItems='center'>
                            <FlexBox flexDirection='column' ml={1.2}>
                                <Small mb={0.5}>{description}</Small>
                            </FlexBox>
                        </FlexBox>
                    );
                }
            },
            {
                minWidth: 50,
                Header: t('paymentMethod.depositablie'),
                accessor: 'depositable',
                Cell: ({ row }: any) => {
                    const { depositable } = row.original;
                    return (
                        <FlexBox alignItems='center'>
                            <FlexBox flexDirection='column' ml={1.2}>
                                {depositable ? <Check /> : <Close />}
                            </FlexBox>
                        </FlexBox>
                    );
                }
            },
            {
                Header: t('employees.agreements.dataTable.modify'),
                Cell: ({ row }: any) => {
                    return (
                        <>
                            <Button
                                onClick={() => {
                                    handleModify(row.original)
                                }}
                            >
                                {t('common.tables.button.editWithItem', {
                                    item: t('amountType.item')
                                })}
                            </Button>
                        </>
                    );
                },
            },
        ]
    }

    return DataTable({
        ...props,
        columns,
    });
}

export default PaymentMethodsDataTable;